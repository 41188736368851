import { Box } from '@mui/material'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Moment } from 'moment'
import moment from 'moment'

type DateTimeFilterProps = {
  label: string
  type: string
}

export const DateTimeFilter: React.FC<DateTimeFilterProps> = ({
  label,
  type,
}) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathName = usePathname()

  const value = searchParams.get(type)
    ? moment(searchParams.get(type)).utcOffset(-moment().utcOffset())
    : null

  return (
    <Box>
      <DateTimePicker
        label={label}
        sx={{ mt: 1 }}
        timezone='system'
        value={value}
        ampm={false}
        disablePast
        slotProps={{
          field: {
            clearable: true,
            onClear: (e) => {
              const input =
                e.currentTarget.parentElement.parentElement.querySelector(
                  'input',
                )
              setTimeout(() => {
                input.blur()
              })
            },
          },
          textField: {
            fullWidth: true,
          },
        }}
        onChange={(value: Moment) => {
          const queryParams = new URLSearchParams(searchParams.toString())

          if (value === null) {
            queryParams.delete(type)
          } else {
            queryParams.set(type, value?.toISOString(true).split('+')[0])
          }

          router.push(pathName + '?' + queryParams.toString())
        }}
      />
    </Box>
  )
}
