import React from 'react'
import { Box, Link, MenuItem, Typography } from '@mui/material'

type SidebarLinkProps = {
  href?: string
  onClick?: () => void
  title?: string
  icon?: React.ReactNode
  selectedIcon?: React.ReactNode
  iconOnly?: boolean
  active?: boolean
  'data-test'?: string
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  href,
  icon,
  selectedIcon,
  iconOnly,
  title,
  active,
  onClick,
  ...props
}) => {
  return (
    <Link onClick={onClick} href={href}>
      <MenuItem
        sx={{
          width: '100%',
          height: '3rem',
          px: !iconOnly ? 0 : '0.5625rem',
        }}
      >
        <Box mr={iconOnly ? 0 : 2} alignSelf='flex-end'>
          {active && selectedIcon ? selectedIcon : icon}
        </Box>
        {!iconOnly && (
          <Typography
            color={active ? 'primary.main' : 'text.primary'}
            fontSize='1em'
            // HACK: This is a hack to make text line up with icons, this is due to a problem with fonts
            // TODO: fix fonts and remove this hack
            mt={0.5}
          >
            {title}
          </Typography>
        )}
      </MenuItem>
    </Link>
  )
}

export { SidebarLink }
