import { Box } from '@mui/material'
import React from 'react'

type NavbarProps = {
  children: React.ReactNode
}

const Navbar: React.FC<NavbarProps> = ({ children }) => {
  return (
    <>
      <Box
        position='sticky'
        zIndex={10}
        top={0}
        bgcolor='background.paper'
        borderLeft='1px solid #FAFAFA'
      >
        <Box
          display='flex'
          alignItems='center'
          ml={3}
          height={68}
          bgcolor='background.paper'
        >
          {children}
          <Box flexGrow={1} />
        </Box>
      </Box>
    </>
  )
}

export { Navbar }
