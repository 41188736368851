'use client'

import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { GlobalModal } from 'src/components/GlobalModal'
import localForage from 'localforage'
import { Suspense, useState } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SessionProvider } from 'next-auth/react'
import { ErrorBoundary } from 'react-error-boundary'
import { FallbackRender } from 'src/components/FallbackRender'
import { Box, ThemeProvider } from '@mui/material'
import { Navbar } from 'src/components/Navbar'
import { NavigationAwareBreadcrumbs } from 'src/elements/NavigationAwareBreadcrumbs'
import { Profile } from 'src/components/Profile'
import { useParams } from 'next/navigation'
import { ToastProvider } from 'src/components/toast'
import { BackdropLoader } from 'src/components/BackdropLoader'
import { theme } from 'src/theme'
import { Sidebar } from 'src/elements/Sidebar'
import { usePathname } from 'next/navigation'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment-timezone'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const TopBar: React.FC = () => {
  return (
    <Navbar>
      <Box
        display='flex'
        justifyContent='space-between'
        width='100%'
        alignItems='center'
      >
        <NavigationAwareBreadcrumbs />
        <Profile />
      </Box>
    </Navbar>
  )
}

const RootLayout = ({ children, params: { session } }) => {
  const pathName = usePathname()
  const params = useParams<{ eventId?: string }>()

  const asyncStoragePersister = createAsyncStoragePersister({
    storage: localForage,
  })

  const showSidebar = !params?.eventId && pathName !== '/profile'

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
          mutations: {
            // We don't retry mutations since they're not idempotent
            // We can allow retries if we implement idempotency keys and idempotency checking on the back-end
            retry: 0,
          },
        },
      }),
  )

  return (
    <html>
      <meta name='referrer' content='no-referrer' />
      <body style={{ margin: 0 }}>
        <SessionProvider session={session} basePath='/api/auth'>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{ persister: asyncStoragePersister }}
            onSuccess={() => {
              // resume mutations after initial restore from localStorage was successful
              queryClient.resumePausedMutations()
            }}
          >
            <ErrorBoundary fallbackRender={FallbackRender}>
              <Suspense fallback={<BackdropLoader open />}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    dateLibInstance={moment}
                  >
                    <ToastProvider>
                      <GlobalModal>
                        <Box
                          minHeight='100vh'
                          height='100vh'
                          width='100%'
                          bgcolor='#FAFAFA'
                          display='flex'
                        >
                          {showSidebar && <Sidebar />}
                          <Box
                            id='scroll-container'
                            flexDirection='column'
                            flex={5}
                            overflow='auto'
                          >
                            <TopBar />
                            <Box flexDirection='column' flex={1} p={2}>
                              {children}
                            </Box>
                          </Box>
                        </Box>
                      </GlobalModal>
                    </ToastProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </Suspense>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistQueryClientProvider>
        </SessionProvider>
      </body>
    </html>
  )
}

export default RootLayout
