import { createTheme, alpha, getContrastRatio } from '@mui/material/styles'
import { Inter } from 'next/font/google'

const inter = Inter({
  subsets: ['latin', 'cyrillic'],
  display: 'swap',
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
})

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    ochre: true
    violet: true
  }
}

import '@mui/material/styles/createPalette'
declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    ochre?: PaletteColorOptions
    violet?: PaletteColorOptions
  }

  interface Palette {
    ochre: PaletteColor
    violet: PaletteColor
  }
}

const violetBase = '#7F00FF'
const violetMain = alpha(violetBase, 0.7)

export const theme = createTheme({
  typography: inter.style,
  palette: {
    primary: {
      main: '#1976D2',
      light: '#4791DB',
      dark: '#115293',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6554C0',
      light: '#8376CC',
      dark: '#463A86',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#36B37E',
      light: '#5EC297',
      dark: '#257D58',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#00B8D9',
      light: '#33C6E0',
      dark: '#008097',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFBB33',
      dark: '#B27700',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF5630',
      light: '#FF7759',
      dark: '#B23C21',
      contrastText: '#FFFFFF',
    },
    ochre: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
    violet: {
      main: violetMain,
      light: alpha(violetBase, 0.5),
      dark: alpha(violetBase, 0.9),
      contrastText:
        getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
    },
  },
})
