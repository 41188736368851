import React from 'react'
// import Logo from "../../Logo";
import { SidebarLink } from './SidebarLink'
import { Box } from '@mui/material'
import { SelectFilter } from '../filters/SelectFilter'
import { useGetAllFilters } from 'src/core/react-query/features/filters'
import { useSearchParams, useRouter } from 'next/navigation'
import { SupervisorAccount } from '@mui/icons-material'
import { PageUrls } from 'src/core/page-urls'
import { useSession } from 'next-auth/react'
import { BackdropLoader } from 'src/components/BackdropLoader'
import { DateTimeFilter } from '../filters/DateTimeFIlter'

const Sidebar: React.FC = () => {
  const { data: session } = useSession()
  const searchParams = useSearchParams()
  const router = useRouter()

  const { data: filters, isLoading } = useGetAllFilters()

  const isAdmin =
    session?.user.email === 'castrew132@gmail.com' ||
    session?.user.id === 'rggf132@gmail.com'

  if (isLoading) return <BackdropLoader open />

  return (
    <Box
      display='flex'
      flexDirection='column'
      bgcolor='background.paper'
      justifyContent='space-between'
    >
      <Box px={3} mt={2.6}>
        <Box>Heyo Header</Box>
      </Box>

      <Box display='flex' flexDirection='column' flex={1} p={2}>
        <Box flex={1}>
          {filters?.map((filter) => {
            const condition = filter.type === 'subCategory'
            const correctOptions = condition
              ? filter.data.filter(
                  (subCategory) =>
                    subCategory.category_id == searchParams.get('category'),
                )
              : filter.data

            return (
              <SelectFilter
                key={filter.id}
                disabled={
                  !searchParams.get('category') && filter.type === 'subCategory'
                }
                label={filter.name}
                type={filter.type}
                options={correctOptions || []}
                sx={{ mt: 1 }}
              />
            )
          })}
          <DateTimeFilter label='Start Time' type='startTime' />
          <DateTimeFilter label='End Time' type='endTime' />
        </Box>
      </Box>
      {session && isAdmin && (
        <Box p={2}>
          <SidebarLink
            title='Admin View'
            onClick={() => router.push(PageUrls.adminHome())}
            icon={<SupervisorAccount />}
          />
        </Box>
      )}
    </Box>
  )
}

export { Sidebar }
