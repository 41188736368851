import { stringify, ParsedUrlQueryInput } from 'querystring'

const queryString = (query: ParsedUrlQueryInput) => {
  const stringified = stringify(query)
  if (stringified) {
    return `?${stringified}`
  } else {
    return ''
  }
}

export const PageUrls = {
  home: () => '/',
  adminHome: () => '/admin/',
  event: ({ eventId }) => `/events/${eventId}`,
  adminEvent: ({ eventId }) => `/admin/events/${eventId}`,
  profile: () => `/profile`,
  test1: () => `/test1`,
  test2: () => `/test2`,
}
