import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { filtersKeys } from '../filtersKeys'
import { RequestTypes } from '../requestTypes'

export type Filter = {
  id: string
  type: 'category' | 'subCategory' | 'location' | 'entryType'
  createEventType:
    | 'categoryId'
    | 'subCategoryIds'
    | 'locationId'
    | 'entryTypeId'
  name: string
  data: FilterData[]
}

export type FilterData = { id: string; category_id?: string; type: string }

export const useGetAllFilters = (
  payload?: RequestTypes['getAllFilters'],
  options?: UseQueryOptions<Filter[], string, Filter[]>,
) => {
  return useQuery<Filter[], string, Filter[]>({
    ...filtersKeys.allFilters(payload),
    refetchOnMount: false,
    ...options,
  })
}
